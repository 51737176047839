body {
  background-color: black;
  max-width: 1200px;
  margin: auto;
  padding-left: 4rem;
  padding-right: 4rem;
  box-sizing: border-box;
  color: white;
  } 

  p {
    font-size: 1em; 
  }

  h1 {
    font-weight:800;
      font-size: 1.7em;
      font-style: italic;
      color: white;
      line-height: 1.25;
  }

  img, video {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
  }

  button{
    font-family: inherit;
    font-size: 100%;
    color: inherit;
  }
  
  
    .navbar{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: -1rem;
      /* margin-bottom: -1rem; */
    }

    .navbarhome{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      /* margin-bottom: -1rem; */
    }
  
    .nbarlefttxt{
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      margin-right: auto;
    }
  
    .mainlogo{
      object-fit: cover;
      height: 8rem;
      max-width: 28rem;
      margin: auto;
      width: 100%;
      z-index: 10;
      
    }

    .TopLandingContainer{

      margin-top: -3rem;
    }
  
    .connecticonsplace, .connecticonsplaceHome {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  
    .connecticonsplacefooter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      flex: 1 1 auto;
      margin-top: 2rem;
      margin-left: 0.5rem;
      z-index: 10;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  
    .connecticons {
      display: inline-block;
      font-weight: 600;
      margin-top: 0px;
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
    }
  
    .frontpagecontainer{
        display: flex;
        justify-content: space-between;
        margin: auto;
    }

    .typewriter {
      font-weight:400;
      font-size: 1em;
      font-style: italic;
      color: white;
      line-height: 1.25;
      max-width: 100%;
      flex-direction: column;
      margin-top: 1rem;
      margin-bottom: 1rem;
      border-top: 1px solid #ffffff !important;
    }

    .welcomesubline {
      font-style: italic;
      font-weight: 500;
      font-size: 1em;
      color: #bfc501;
      line-height: 2.25;
    }
  
    .welcomeset {
        position: relative;
        margin-top: -5rem;
        flex: 0 0 75%;
        align-items: center;
        flex-shrink: 0;
        z-index: 10;
        max-width: 23rem;
        padding-bottom: 1.5rem;
    }
   
    .welcomemsg {
      font-weight:800;
      font-size: 1.7em;
      font-style: italic;
      color: white;
      line-height: 1.25;
      margin: 0;
    }
  
    .welcomebtn {
      background-color:#bfc501;
      border-color: #bfc501;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 2rem;
      padding-right: 2rem;
      border-radius: 0.5rem;
      margin-top: 1rem;
      font-weight: 700;
      color: #000;
      width: 65%;

      
    }

    .mintbtn {
      background-color:#bfc501;
      border-color: #bfc501;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0.5rem;
      margin-top: 2.3rem;
      font-weight: 700;
      color: #000;
      width: 239px;

      
    }

    .tophighlight {
      background-color:#bfc501;
      border-color: #bfc501;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0.25rem;
      margin-top: 1rem;
      font-weight: 700;
      color: black;
      width: 100%;
      text-align: center;
    }
  
    .cnotice{
      display: flex;
      justify-content: flex-end;
      color:white;
    }
  

    .TopMint{
      display: flex;
     justify-content: center;
     margin: auto;
    }
    .SectionLast {
 display: inline-block;
 position: relative;
 padding-top: 5rem;
    }

    .SectionLast video {
padding-top: 10rem;
   }
  
    .WLFish img {
      width: 35%;
      margin: unset;
    }

    .SectionLast img + .WLFish {
      position: absolute;
      bottom: 0;
      left: 0;
  }

  .SectionThreeA {
     display: flex;
     justify-content: center;
     margin: auto;
     flex-direction: row;

  }

  .SingleNFTPic{
    position: relative;
    max-width: 80%;
    left:20%
  }

.SectionAnchorPoints{
display: flex;
text-align: center;
align-items: baseline;
font-size: 0.8rem;
}
  

.SectionFAQ{
  padding-bottom:5rem;
}

.imglabel{
  position: relative;
  text-align: center;
  font-size: 1rem;
  background-color: black;
}

.TopGallery{
  display: flex;
}

.OSImage{
  position: relative;
  border-bottom: 0.1px solid black;
}


.OSImageOverlay {
  position: absolute;
  left: 0;
  top:0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1; 
  width:40%;
  transition: .5s ease;
  opacity:0.5;
  color: white;
  font-size: 20px;
}


.SectionWelcome{
  padding-top: 2rem;

}

.SectionSpecs {
  display: grid;
  grid-template-columns: auto auto;} 


  
.SectionSpecsGallery{
  display: grid;
  grid-template-columns: auto auto;
  margin: 2rem;
  margin-left: 1rem;
}

.SectionSpecsGalleryBABD1{
  padding-bottom: 0.5rem;
  padding-right: 1rem;
}
.SectionSpecsGalleryBABD2{
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}
.SectionSpecsGalleryBABD3{
  padding-top: 0.5rem;
  padding-right: 1rem;
}
.SectionSpecsGalleryBABD4{
  padding-top: 0.5rem;
  padding-left: 1rem;
}


.SectionSlimeGrid, .SectionVoodoo{
  display: flex;
  flex-wrap: wrap;
}

.SectionSlimeGrid img{
  max-width: 27rem;
}

.SectionVoodoo img{
  max-width: 13rem;
}

.PipeDivider{
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.PipeDivider2{
  padding-top: 3rem;
  padding-bottom: 3rem;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}



.footerbar{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 2rem;
}

.footerlogoplace{
  display: flex;
  align-items: center;

}

.footerlogo{
  object-fit: scale-down;
  height: 12rem;
  max-width: 28rem;
  margin: auto;
  width: 100%;
  
}

.bottomline{
    border-bottom: 15px solid #bfc501;
    display: block;
    padding-top: 30px;
    max-width: 100vw;
}


.ImgPreLoad{
  display: none;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}



   @media screen and (max-width: 1024px) {
      .connecticonsplace {display: none;}
      .navbar{  margin-bottom: 1rem; }
      .welcomeset { margin-top: -1rem;}
      body {padding-left: 1rem; padding-right: 1rem;}
      .SectionSpecs,  .frontpagecontainer {grid-template-columns: auto;}
      .SectionAnchorPoints, .frontpagecontainer, .footerbar {flex-direction:column;}
      .SectionSlimeGrid img {
        max-width: 100%;
        margin-left: 0rem;
      }
      [class*="col-"] {
        width: 100%;
      }
    }